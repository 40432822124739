import React from "react"
import { graphql } from "gatsby"
import Showcase from "../components/Showcase/Showcase"

export default ({ data }) => {
  const items = data.allMarkdownRemark.nodes.map(node => {
    const sources = [
      node.frontmatter.image.childImageSharp.mobile,
      {
        ...node.frontmatter.image.childImageSharp.desktop,
        media: `(min-width: 900px)`,
      },
    ]

    return {
      title: node.frontmatter.title,
      imageSources: sources,
      path: node.fields.slug,
    }
  })

  return <Showcase items={items} />
}

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { eq: "service-page" }
          category: { in: "extras" }
        }
      }
    ) {
      nodes {
        frontmatter {
          title
          image {
            childImageSharp {
              desktop: fluid(maxWidth: 2000, quality: 80) {
                ...GatsbyImageSharpFluid_noBase64
              }
              mobile: fluid(maxWidth: 1000, quality: 80) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        fields {
          slug
        }
      }
    }
  }
`
