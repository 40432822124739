import React, { useState, useEffect } from "react"
import BackgroundImage from "gatsby-background-image"
import { Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import classes from "./Showcase.module.scss"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import { useDispatch, useSelector } from "react-redux"
import { setFrontIsActive, setPrevFrontIsActive } from "../../state/app"
import Arrow from "../../assets/images/arrow-right.inline.svg"

const Showcase = ({ className, items }) => {
  const [currentItemIndex, setCurrentItemIndex] = useState(0)
  const currentItem = items[currentItemIndex]
  const dispatch = useDispatch()
  const frontIsActive = useSelector(state => state.app.frontIsActive)
  const SHOWCASETIME = 5000

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentItemIndex(prevIndex => {
        if (prevIndex < items.length - 1) {
          return prevIndex + 1
        } else {
          return 0
        }
      })
    }, SHOWCASETIME)
    return () => clearInterval(interval)
  })

  return (
    <div className={classes.ShowcaseWrapper}>
      <TransitionGroup
        component={null}
        childFactory={child => React.cloneElement(child)}
      >
        <CSSTransition
          in={true}
          timeout={{
            appear: 200,
            enter: 200,
            exit: 0,
          }}
          classNames={classes}
          key={currentItem.title}
        >
          <BackgroundImage
            Tag={`div`}
            className={className}
            fluid={currentItem.imageSources}
          >
            <h1 className="visuallyhidden">
              Dream Day - Esküvők és rendezvények
            </h1>
            <div className={classes.Target}>
              <h3 className={classes.TargetSummary}>Tedd emlékezetessé!</h3>
              <h2 className={classes.TargetTitle}>
                <Link
                  to={currentItem.path}
                  onClick={() => {
                    dispatch(setPrevFrontIsActive(frontIsActive))
                    dispatch(setFrontIsActive(false))
                  }}
                >
                  {currentItem.title}
                  <Arrow className={classes.Arrow} />
                </Link>
              </h2>
            </div>
          </BackgroundImage>
        </CSSTransition>
      </TransitionGroup>
      {currentItemIndex < items.length - 1 ? (
        <div
          style={{
            visibility: "hidden",
            width: 0,
            height: 0,
            overflow: "hidden",
          }}
        >
          <Img fluid={items[currentItemIndex + 1].imageSources} />
        </div>
      ) : null}
    </div>
  )
}

export default styled(Showcase)`
  width: 100%;
  min-height: 100%;
  background-color: transparent;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`
